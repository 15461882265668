export enum ServiceSet {

    ESTIMATED_TAX_PAYMENT_PROCESSING = "ESTIMATED_TAX_PAYMENT_PROCESSING",
    ESTIMATED_TAX_PAYMENT_SUBSCRIPTION = "ESTIMATED_TAX_PAYMENT_SUBSCRIPTION",
    ESTIMATED_TAX_PAYMENT_PROCESSING_WITH_SUBSCRIPTION = "ESTIMATED_TAX_PAYMENT_PROCESSING_WITH_SUBSCRIPTION",
    EXEMPTION_FROM_WORKERS_COMPENSATION_INSURANCE = "EXEMPTION_FROM_WORKERS_COMPENSATION_INSURANCE",
    EMPLOYER_REGISTRATION_WITH_DOL = "EMPLOYER_REGISTRATION_WITH_DOL",
    SALES_TAX_VENDOR_REGISTRATION = "SALES_TAX_VENDOR_REGISTRATION",
    STATUS_S_CORP = "STATUS_S_CORP",
    BOI_REPORT = "BOI_REPORT",

}
