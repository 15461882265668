
















































































import {Component, Prop, Vue} from 'vue-property-decorator';
import {AddBOIReportPayload} from "@/dto/fincen/AddBOIReportPayload";
import SimpleCompanySearch from "@/components/common/SimpleCompanySearch.vue";
import CompanyDTO from "@/dto/company/CompanyDTO";
import PortalSelect from "@/components/common/PortalSelect.vue";
import {BOIReportType} from "@/constants/FinCENConstants";
import SelectOption, {OptionsBuilder} from "@/components/common/SelectOption";
import PortalDate from "@/components/common/PortalDate.vue";
import PortalCheckbox from "@/components/common/PortalCheckbox.vue";
import SingleFileHolder from "@/components/util/SingleFileHolder.vue";
import {namespace} from "vuex-class";
import {ifValid, processError} from "@/utils/ComponentUtils";
import FinCENService from "@/services/request/FinCENService";
import PortalInput from "@/components/common/PortalInput.vue";
import {SublimeCompanyType} from "@/dto/SublimeCompanyType";
import {PaymentMethod} from "@/constants/PaymentConstants";
import {ServiceSet} from "@/dto/payment/ServiceSet";
import BOIInvoiceDTO from "@/dto/fincen/BOIInvoiceDTO";

const AppModule = namespace("App");

@Component<AddBOIRequest>({
  components: {
    PortalInput,
    SingleFileHolder,
    PortalCheckbox,
    PortalDate,
    PortalSelect,
    SimpleCompanySearch
  },
  computed: {
    SelectOption() {
      return SelectOption;
    },
    Opts() {
      return OptionsBuilder;
    },
    BRT() {
      return BOIReportType
    },
    SublimeCompanyType() {
      return SublimeCompanyType;
    },
    PaymentMethod() {
      return PaymentMethod;
    }
  }
})
export default class AddBOIRequest extends Vue {

  @AppModule.Action  private startLoading!: () => void;

  @AppModule.Action  private stopLoading!: () => void;

  @Prop()
  private correctedId?: number;

  @Prop()
  private companyId?: number;

  @Prop()
  private onSave!: () => void;

  payload = new AddBOIReportPayload();

  created(): void {
    this.payload.correctedReportId = this.correctedId;
    this.payload.companyId = this.companyId;
    this.payload.invoice = new BOIInvoiceDTO();
    this.payload.invoice.service = ServiceSet.BOI_REPORT;
    if (this.correctedId) {
      this.payload.type = BOIReportType.CORRECTED;
    }
  }

  companyChanged(company: CompanyDTO): void {
    this.payload.companyId = company.id
  }

  saveReport(): void {
    ifValid(this, () => {
      if (this.isInvoiceValid && this.isReportValid) {
        this.startLoading();
        if(!this.payload.paid){
          this.payload.invoice = null
        } else {
          this.payload.invoice!.sublimeCompany = SublimeCompanyType.SUBLIME_CONSULTING;
        }
        FinCENService.addBOIReport(this.payload).then(
            ok => {
              this.stopLoading();
              this.$modal.hideAll();
              this.onSave();
            },
            err => {
              this.stopLoading();
              processError(err, this);
            }
        )
      }
    })
  }

  private formatString(str: string): string {
    const words = str.toLowerCase().split('_');
    return words.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
  }

  get isCorrected(): boolean {
    return this.payload.correctedReportId != null;
  }

  get isInvoiceValid(): boolean {
    return !this.payload.paid || !!this.payload.invoice?.document;
  }

  get isReportValid(): boolean {
    return !!this.payload.reportFileId;
  }

}
