import FileMetaDTO from "@/dto/files/FileMetaDTO";
import { SublimeCompanyType } from "@/dto/SublimeCompanyType";
import { PaymentMethod } from "@/constants/PaymentConstants";
import { ServiceSet } from "@/dto/payment/ServiceSet";

export default class BOIInvoiceDTO {

    document: FileMetaDTO | null = null;
    sublimeCompany: SublimeCompanyType | null = null;
    invoiceNumber: string | null = null;
    invoiceDate: Date | null = null;
    dueDate: Date | null = null;
    paymentMethod: PaymentMethod | null = null;
    paymentAmount: number | null = null;
    service: ServiceSet | null = null;

}
